import axios, { AxiosResponse } from 'axios';
import { WebAppActionResult } from 'src/shared/models/WebAppActionResult';
import { UserInformationMessageLightModel } from '../models/UserInformationMessageLightModel';

const controllerUrl = 'api/UserInformationMessageReferential/';

export class UserInformationMessagesReferentialApiClient {
    public static GetUserInformationMessages = ()
        : Promise<AxiosResponse<Array<UserInformationMessageLightModel>>> => {
        return axios.get(`${controllerUrl}GetUserInformationMessages`);
    }

    public static UpdateUserInformationMessage = (requestArgs: UserInformationMessageLightModel)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateUserInformationMessage`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static AddUserInformationMessage = (requestArgs: UserInformationMessageLightModel)
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddUserInformationMessage`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(requestArgs)
        });
    }

    public static RemoveUserInformationMessages = (ids: number[])
        : Promise<AxiosResponse<WebAppActionResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}RemoveUserInformationMessage`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ Ids: ids })
        });
    }
}
