import { faAt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Checkbox, Drawer, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { UploadFileInfo, UploadOnAddEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import React, { useState } from 'react';
import SimpleDialog from 'src/shared/components/Common/SimpleDialog';
import { BusinessErrorLight, CommandResultLight } from 'src/shared/models/WebAppActionResult';
import ToastService from 'src/ToastService';
import BusinessErrors from 'src/utils/BusinessErrors';
import { AppModule, LocalStorage } from 'src/utils/Storage';
import { AddedAttachmentResult } from '../services/dataContracts/controller/AddedAttachmentResult';
import { AttachmentArgs } from '../services/dataContracts/controller/AttachmentArgs';
import { AttachmentStatus } from '../services/dataContracts/controller/AttachmentStatus';
import { DeleteAttachmentArgs } from '../services/dataContracts/controller/DeleteAttachmentArgs';
import { TransporterLightModel } from '../services/dataContracts/queryStack/TransporterLightModel';
import { TransportPurchasesApiClient } from '../services/TransportPurchasesApiClient';
import { TransporterListEmailNotificationsDialogComponent } from './TransporterListEmailNotificationsDialogComponent';

interface TransporterListComponentProps {
    isTransporterDrawerOpened: boolean,
    transporters: Array<TransporterLightModel>,
    selectedTransporters: Array<string>,
    _handleOpenCloseTransporterDrawer: () => void,
    _toggleCheckbox: (transporter: TransporterLightModel) => void
    _toggleAllCheckboxs: (isAllSelected: boolean) => void
}

export const TransporterListComponent = (props: TransporterListComponentProps) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isTransportersEmailNotificationsDialogOpened, setIsTransportersEmailNotificationsDialogOpened] = useState<boolean>(false);
    const [attachmentFiles, setAttachmentFiles] = useState<AttachmentArgs[]>([]);
    const [isLoadingAttachment, setIsLoadingAttachment] = useState<boolean>(false);
    const [isOverSizeAttachements, setIsOverSizeAttachements] = useState<boolean>(false);

    const ModuleKey = AppModule.TransportPurchases;
    const openSendMailDialog = (): void => {
        setIsTransportersEmailNotificationsDialogOpened(true);
    }

    const updateAllElementsChoices = (e: React.ChangeEvent<HTMLInputElement>): void => {
        var isAllSelected = selectAll;
        setSelectAll(!isAllSelected);
        props._toggleAllCheckboxs(!isAllSelected);
    }

    const handleCloseTransportersEmailNotificationsDialog = (): void => {
        deleteNewAttachmentFiles(attachmentFiles);
        setIsTransportersEmailNotificationsDialogOpened(false)
        LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '0');
    }

    const toggelSelectAll = (): void => {
        let selectAllValue: boolean = null;
        if (props.transporters.length == 1) {
            if (props.selectedTransporters.length == 0) {
                setSelectAll(true);
                selectAllValue = true;
            }

            if (props.selectedTransporters.length == 1) {
                setSelectAll(false);
                selectAllValue = false;
            }
        }
        else {
            if (props.selectedTransporters.length == 0 || props.selectedTransporters.length == props.transporters.length - 1) {
                setSelectAll(false);
                selectAllValue = false;
            }
            if (props.selectedTransporters.length == props.transporters.length - 1) {
                setSelectAll(true);
                selectAllValue = true;
            }
        }

        if (selectAllValue != null)
            props._toggleAllCheckboxs(selectAllValue);
    }

    const handlerAfterUploadAttachment = (attachments: AddedAttachmentResult[]): void => {
        if (!isOverSizeAttachements) {
            const attachmentFilesArray = [...attachmentFiles];
            const toAddAttachments: AttachmentArgs[] = attachments.map(x => {
                return {
                    attachmentId: x.attachmentId,
                    name: x.name,
                    status: AttachmentStatus.new,
                    size: x.size
                }
            });
            Array.prototype.push.apply(attachmentFilesArray, toAddAttachments);
            setAttachmentFiles(attachmentFilesArray);
            setIsLoadingAttachment(false);
        } else {
            setAttachmentFiles([]);
        }
    }

    const handlerDisplayErrorsAfterUpload = (commandResults: CommandResultLight[]): void => {
        const errorAddedFileNumber: number = commandResults.length;
        const errorText: string = errorAddedFileNumber > 1 ? errorAddedFileNumber + " fichier(s) n'ont pas été ajouté" : " fichier n'a pas été ajouté";
        const lstErrorToastMessages: string[] = [];

        commandResults.forEach((command: CommandResultLight) => {
            command.businessErrors.forEach((businessError: BusinessErrorLight) => {
                const error = BusinessErrors.GetError({ type: businessError.type, name: businessError.name });
                if (!lstErrorToastMessages.includes(error))
                    lstErrorToastMessages.push(error);
            });
        });

        ToastService.showErrorToast(errorText, lstErrorToastMessages);
    }

    const handleDeleteAttachment = (attachmentId: string, state: AttachmentStatus): void => {
        setIsLoadingAttachment(true);
        if (state === AttachmentStatus.new) {
            deleteAttachment(attachmentId);
        } else {
            const attachmentFilesArray = [...attachmentFiles];
            const attachment = attachmentFilesArray.find(x => x.attachmentId === attachmentId);
            attachment.status = AttachmentStatus.deleted;
            setAttachmentFiles(attachmentFilesArray);
            setIsLoadingAttachment(false);
        }
    }

    const deleteAttachment = (attachmentId: string): void => {
        const requestArgs: DeleteAttachmentArgs = { attachmentIds: [attachmentId] };
        TransportPurchasesApiClient.DeleteAttachments(requestArgs)
            .then((res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    setIsLoadingAttachment(false);
                    return;
                }

                const attachments = attachmentFiles.filter(x => x.attachmentId !== attachmentId);
                const attachmentToDelete = attachmentFiles.filter(x => x.attachmentId === attachmentId);
                setAttachmentFiles(attachments);
                var sizeAttachementsString = LocalStorage.GetItem(ModuleKey, "purchaseAttachmentsSize");
                var sizeAttachements = parseInt(sizeAttachementsString, 10);
                var newSize = sizeAttachements - attachmentToDelete[0].size;
                LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '' + newSize);
                setIsLoadingAttachment(false);
            });
    }

    const handleAddAttachments = async (e: UploadOnAddEvent): Promise<void> => {
        var size = 0;
        e.affectedFiles.forEach((file: UploadFileInfo) => {
            size += file.size;
        });
        var sizeAttachementsString = LocalStorage.GetItem(ModuleKey, "purchaseAttachmentsSize");
        var sizeAttachements = 0;
        if (sizeAttachementsString) {
            var sizeAttachements = parseInt(sizeAttachementsString, 10);
        }

        size += sizeAttachements;
        LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '' + size);
        if (size > 15000000) {
            setIsOverSizeAttachements(true);
            setAttachmentFiles([]);
            LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '0');
            ToastService.showErrorToast("Les fichiers déposés ne doivent pas dépasser la taille de 15 Mo");
            await deleteNewAttachmentFiles(attachmentFiles);
            setIsLoadingAttachment(false);
            return;
        }
        setIsOverSizeAttachements(false);
    }

    const handleCloseDialogAfterSendEmail = () => {
        setAttachmentFiles([]);
        LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '0');
        setIsTransportersEmailNotificationsDialogOpened(false);
    }

    const handlerBeforeUploadAttachment = (e: UploadOnBeforeUploadEvent): void => {
        setIsLoadingAttachment(true);
    }

    const deleteNewAttachmentFiles = async (attachmentFiles: AttachmentArgs[]): Promise<void> => {
        if (attachmentFiles != null && attachmentFiles.length > 0) {
            const attachmentIds = attachmentFiles.filter(x => x.status === AttachmentStatus.new).map(x => x.attachmentId);
            if (attachmentIds.length > 0) {
                const requestArgs: DeleteAttachmentArgs = { attachmentIds: attachmentIds };
                try {
                    LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '0');
                    setAttachmentFiles([]);
                    const res = await TransportPurchasesApiClient.DeleteAttachments(requestArgs);
                    const data = res?.data;
                    const errors = BusinessErrors.Get(data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("", errors);
                    }
                } catch (error) {
                    console.error("Error deleting attachments:", error);
                    ToastService.showErrorToast("", ["Failed to delete attachments."]);
                } finally {
                    setIsLoadingAttachment(false);
                }
            }
        }
        LocalStorage.SetItem(ModuleKey, "purchaseAttachmentsSize", '0');
        setAttachmentFiles([]);
    }

    return (
        <>
            <Drawer
                className={props.isTransporterDrawerOpened ? 'transporter-drawer open' : 'transporter-drawer'}
                variant="persistent"
                anchor="left"
                open={props.isTransporterDrawerOpened}>
                <Box display="flex" p={2} flexDirection="column" flex='nowrap' className='content-transporter-drawer'>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems='center' className="transporter-drawer-header">
                        <Box width="15%" pl={1}>
                            <Checkbox color="primary" indeterminate={props.selectedTransporters.length > 0 && props.transporters.length != props.selectedTransporters.length} checked={selectAll} onChange={(e) => updateAllElementsChoices(e)} className="custom-checkbox" />
                        </Box>
                        <Box width="65%" className='drawer-title'>
                            Transporteurs
                        </Box>
                        <Box pl={1}>
                            <Tooltip title="Envoyer email" placement="bottom">
                                <Avatar className="btn-actions">
                                    <IconButton size="small" className="btn-icon" disabled={props.selectedTransporters.length == 0} aria-label="SendMail" onClick={() => openSendMailDialog()}>
                                        <FontAwesomeIcon icon={faAt} />
                                    </IconButton>
                                </Avatar>
                            </Tooltip>
                        </Box>
                        <Box display="flex" width="20%" flexDirection="row" justifyContent="flex-end">
                            <Box style={{ cursor: 'pointer' }} onClick={() => props._handleOpenCloseTransporterDrawer()} >
                                <FontAwesomeIcon size="lg" icon={faTimes} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" className="transporter-drawer-body">
                        {props.transporters.map((transporter: TransporterLightModel) => (
                            <FormControlLabel key={transporter.transporterId} className={(props.selectedTransporters.indexOf(transporter.transporterId) > -1 || selectAll) ? 'transporter-item selected-transporter' : 'transporter-item'}
                                control={
                                    <Checkbox color="primary" checked={props.selectedTransporters.indexOf(transporter.transporterId) > -1} onChange={() => props._toggleCheckbox(transporter)} onClick={() => toggelSelectAll()} />
                                }
                                label={
                                    <Box display="flex" flexWrap="nowrap" flexDirection="row" justifyContent="space-between" className={transporter.allVehiclesIdentified == true ? 'black-cls' : 'orange-cls'}>
                                        <Box>{transporter.transporterName}</Box>
                                        <Box className="vehicle-count">{transporter.vehiclesCount}</Box>
                                    </Box>
                                }
                            />
                        ))}
                    </Box>
                </Box>
            </Drawer>

            <SimpleDialog isOpen={isTransportersEmailNotificationsDialogOpened}
                onClose={handleCloseTransportersEmailNotificationsDialog}
                dialogTitle={`Envoi email aux ${props.selectedTransporters.length} transporteurs sélectionnés`}
                closeIcon={true}
                classNameVal="transporters-email-notifications-dialog"
                headerBorder={true}
                component={<TransporterListEmailNotificationsDialogComponent
                    attachmentFiles={attachmentFiles}
                    isLoadingAttachments={isLoadingAttachment}
                    selectedTransporters={props.selectedTransporters}
                    allTransporters={props.transporters}
                    handleAdd={handleAddAttachments}
                    handleDeleteAttachment={handleDeleteAttachment}
                    handlerAfterUploadAttachment={handlerAfterUploadAttachment}
                    handlerBeforeUpload={handlerBeforeUploadAttachment}
                    handlerDisplayErrorsAfterUpload={handlerDisplayErrorsAfterUpload}
                    handleCloseDialogAfterSendEmail={handleCloseDialogAfterSendEmail}
                />
                }
            />
        </>
    );
}
