import { GridColumnProps } from '@progress/kendo-react-grid';
import { LogisticsUnitChoice } from '../shared/models/LogisticsUnitChoice';

export enum AppModule {
    TransportPlan = "TransportPlan",
    OperationalMonitoring = "OperationalMonitoring",
    TransportFlow = "TransportFlow",
    TransportPurchases = "TransportPurchases",
    TransportSales = "TransportSales",
    PurchaseProvisionings = "PurchaseProvisionings",
    Reservation = "Reservation",

    ThirdPartyBeneficiary = "ThirdPartyBeneficiary",
    ThirdPartyBeneficiaryInvoices = "ThirdPartyBeneficiaryInvoices",
    ThirdPartyProduction = "ThirdPartyProduction",
    ThirdPartyTransporter = "ThirdPartyTransporter",
    ThirdPartyInternalTransporterPublishedCosts = "ThirdPartyInternalTransporterPublishedCosts",
    InternalTransporterPublishedCosts = "InternalTransporterPublishedCosts",
    ThirdPartyExternalTransporterPublishedCosts = "ThirdPartyExternalTransporterPublishedCosts",

    RequestedTransportationsExport = "RequestedTransportationsExport",
    TransportPlanExport = "TransportPlanExport",

    CustomerContactsReferential = "CustomerContactsReferential",
    InternalContactsReferential = "InternalContactsReferential",
    InternalDriversReferential = "InternalDriversReferential",
    TemporaryDriversReferential = "TemporaryDriversReferential",
    InternalVehiclesReferential = "InternalVehiclesReferential",
    Level2ActivitiesReferential = "Level2ActivitiesReferential",
    Level3SegmentationsReferential = "Level3SegmentationsReferential",
    OverheadExpensesReferential = "OverheadExpensesReferential",
    ProductionSitesReferential = "ProductionSitesReferential",
    GenericPurchasePricesReferential = "GenericPurchasePricesReferential",
    ExternalTransportPurchasePricesReferential = "ExternalTransportPurchasePricesReferential",
    InternalTransportPurchasePricesReferential = "InternalTransportPurchasePricesReferential",
    WorkAgenciesReferential = "WorkAgenciesReferential",
    DieselIndexesReferential = "DieselIndexesReferential",
    NegotiatedSellPricesReferential = "NegotiatedSellPricesReferential",
    NegotiatedPurchasePricesReferential = "NegotiatedPurchasePricesReferential",
    DispatchersReferential = "DispatchersReferential",
    DispatcherAgenciesReferential = "DispatcherAgenciesReferential",
    QuotationPricesReferential = "QuotationPricesReferential",
    LogisticsUnitLogiticiansReferential = "LogisticsUnitLogiticiansReferential",
    UserInformationMessagesReferential = "UserInformationMessagesReferential",

    VehiclesSelector = "VehiclesSelector",

    Cartography = "Cartography"
}

class StorageKeyBuilder {

    public static BuildItemKey(module: AppModule, localKey: string): string {
        const globalKeyName = `${module}/item_${localKey}`;
        return globalKeyName;
    }

    public static BuildGridColumnWidthKey(module: AppModule, gridName: string, columnName: string): string {
        const globalKeyName = `${module}/grid_${gridName}/columnWidth_${columnName}`;
        return globalKeyName;
    }

    public static BuildGridColumnsOrderIndexesKey(module: AppModule, gridName: string): string {
        const globalKeyName = `${module}/grid_${gridName}/columnsOrderIndexes`;
        return globalKeyName;
    }
}

export class SessionStorage {

    static readonly ActiveStartDateKey = "_activeStartDate";

    static readonly ActiveEndDateKey = "_activeEndDate";

    static readonly LastKnownApiAccessTokenKey = "_lastKnownApiAccessToken";

    private static _activeStartDate: Date = null;

    private static _activeEndDate: Date = null;

    private static _lastKnownApiAccessToken: string = null;

    public static GetItem(module: AppModule, localKey: string): string {
        const keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        return sessionStorage.getItem(keyName);
    }

    public static SetItem(module: AppModule, localKey: string, value: string): void {
        const keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        sessionStorage.setItem(keyName, value)
    }

    public static get ActiveStartDate(): Date {

        if (SessionStorage._activeStartDate !== null)
            return SessionStorage._activeStartDate;

        const json: string = sessionStorage.getItem(SessionStorage.ActiveStartDateKey);
        if (json !== null) {

            let date: Date = null;
            try {
                date = Date.parseJSON(json);
            }
            catch (error) {
                date = new Date().getDayStart();
            }
            SessionStorage._activeStartDate = date;
            return SessionStorage._activeStartDate;
        }
        else {
            SessionStorage._activeStartDate = new Date().getDayStart();
        }

        return SessionStorage._activeStartDate;
    }

    public static set ActiveStartDate(value: Date) {
        SessionStorage._activeStartDate = value;
        const json: string = value.toJSON();
        sessionStorage.setItem(SessionStorage.ActiveStartDateKey, json);
    }

    public static get ActiveEndDate(): Date {

        if (SessionStorage._activeEndDate !== null)
            return SessionStorage._activeEndDate;

        const json: string = sessionStorage.getItem(SessionStorage.ActiveEndDateKey);
        if (json !== null) {

            let date: Date = null;
            try {
                date = Date.parseJSON(json);
            }
            catch (error) {
                date = new Date().getDayEnd();
            }
            SessionStorage._activeEndDate = date;
            return SessionStorage._activeEndDate;
        }
        else {
            SessionStorage._activeEndDate = new Date().getDayEnd();
        }

        return SessionStorage._activeEndDate;
    }

    public static set ActiveEndDate(value: Date) {
        SessionStorage._activeEndDate = value;
        const json: string = value.toJSON();
        sessionStorage.setItem(SessionStorage.ActiveEndDateKey, json);
    }

    /**
     * //NOTE CMA A utiliser uniquement sur des scenarios "exotiques" comme dans le cas des composants kendo Upload
     */
    public static GetLastKnownApiAccessToken(): string {

        if (SessionStorage._lastKnownApiAccessToken === null) {
            SessionStorage._lastKnownApiAccessToken = sessionStorage.getItem(SessionStorage.LastKnownApiAccessTokenKey);
        }

        if (SessionStorage._lastKnownApiAccessToken === null)
            throw Error("Token not set! Verify a token has been set using SetLastKnownApiAccessToken before using this method.");

        return SessionStorage._lastKnownApiAccessToken;
    }

    /**
     * //NOTE CMA A utiliser uniquement sur des scenarios "exotiques" comme dans le cas des composants kendo Upload
     */
    public static SetLastKnownApiAccessToken(value: string) {

        const current = SessionStorage._lastKnownApiAccessToken;
        if (value === current)
            return;

        SessionStorage._lastKnownApiAccessToken = value;
        sessionStorage.setItem(SessionStorage.LastKnownApiAccessTokenKey, value);
    }
}

export class LocalStorage {

    static readonly LogisticsUnitsChoicesKey = "_logisticsUnitsChoices";

    private static _gridColumnsOrderIndexesCache: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();

    private static _logisticsUnitsChoices: LogisticsUnitChoice[] = null;

    public static GetItem(module: AppModule, localKey: string): string {
        const keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        return localStorage.getItem(keyName);
    }

    public static SetItem(module: AppModule, localKey: string, value: string): void {
        const keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        localStorage.setItem(keyName, value);
    }

    public static RemoveItem(module: AppModule, localKey: string): void {
        const keyName: string = StorageKeyBuilder.BuildItemKey(module, localKey);
        localStorage.removeItem(keyName);
    }

    public static GetGridColumnWidth(module: AppModule, gridName: string, fieldName: string, defaultWidth: number): number {

        const key: string = StorageKeyBuilder.BuildGridColumnWidthKey(module, gridName, fieldName);
        const value = localStorage.getItem(key);

        if (value != null) {
            try {
                return Number(value);
            }
            catch {
            }
        }

        return defaultWidth;
    }

    public static SetGridColumnWidth(module: AppModule, gridName: string, fieldName: string, width: string | number): void {
        const key: string = StorageKeyBuilder.BuildGridColumnWidthKey(module, gridName, fieldName);
        localStorage.setItem(key, width as string);
    }

    public static GetGridColumnOrderIndex(module: AppModule, gridName: string, fieldName: string, defaultIndex: number): number {

        const key: string = StorageKeyBuilder.BuildGridColumnsOrderIndexesKey(module, gridName);

        let indexesMap: Map<string, number> = LocalStorage._gridColumnsOrderIndexesCache[key];

        //already cached 
        if (indexesMap !== null && indexesMap !== undefined && indexesMap.size > 0) {

            const fieldIndex: number = indexesMap[fieldName];
            if (fieldIndex !== null && fieldIndex !== undefined)
                return fieldIndex;

            return defaultIndex;
        }

        //get from storage and cache
        const json = localStorage.getItem(key);
        if (json != null) {
            try {
                indexesMap = JSON.parse(json);
                LocalStorage._gridColumnsOrderIndexesCache[key] = indexesMap;

                const fieldIndex: number = indexesMap[fieldName];
                if (fieldIndex !== null && fieldIndex !== undefined)
                    return fieldIndex;
            }
            catch {
            }
        }

        return defaultIndex;
    }

    public static SetGridColumnsOrderIndexes(module: AppModule, gridName: string, columns: GridColumnProps[]) {

        const key: string = StorageKeyBuilder.BuildGridColumnsOrderIndexesKey(module, gridName);

        const indexesMap: Map<string, number> = new Map<string, number>();
        columns.forEach((column) => indexesMap[column.field] = column.orderIndex);

        LocalStorage._gridColumnsOrderIndexesCache[key] = indexesMap;
        localStorage.setItem(key, JSON.stringify(indexesMap));
    }

    public static get LogisticsUnitsChoices(): LogisticsUnitChoice[] {
        if (LocalStorage._logisticsUnitsChoices !== null)
            return LocalStorage._logisticsUnitsChoices;

        const json: string = localStorage.getItem(LocalStorage.LogisticsUnitsChoicesKey);
        if (json !== null) {

            let logisticsUnitFilterChoice: LogisticsUnitChoice[] = null;
            try {
                logisticsUnitFilterChoice = JSON.parse(json);
            }
            catch (error) {
                logisticsUnitFilterChoice = null;
            }
            LocalStorage._logisticsUnitsChoices = logisticsUnitFilterChoice;
            return LocalStorage._logisticsUnitsChoices;
        }

        return LocalStorage._logisticsUnitsChoices;
    }

    public static set LogisticsUnitsChoices(value: LogisticsUnitChoice[]) {
        LocalStorage._logisticsUnitsChoices = value;
        const json: string = JSON.stringify(value);
        localStorage.setItem(LocalStorage.LogisticsUnitsChoicesKey, json);
    }

    public static RemoveLogisticsUnitsChoices() {
        LocalStorage._logisticsUnitsChoices = null;
        localStorage.removeItem(LocalStorage.LogisticsUnitsChoicesKey);
    }
}