import { Box } from '@mui/material';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import { ScaleLoaderComponent } from '../../../shared/components/Common/ScaleLoaderComponent';
import '../ReferentialsStyles.scss';
import './UserInformationMessagesReferentialStyles.scss';
import { UserInformationMessagesReferentialApiClient } from './services/UserInformationMessagesReferentialApiClient';
import { ErrorModel, UserInformationMessageExtended, UserInformationMessageLightModel } from './models/UserInformationMessageLightModel';
import { UserInformationMessageListComponent } from './components/UserInformationMessageListComponent';
import { AddUserInformationMessageComponent } from './components/AddUserInformationMessageComponent';
import { AxiosResponse } from 'axios';
import { WebAppActionResult } from '../../../shared/models/WebAppActionResult';

export const UserInformationMessagesReferentialView = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [userInfoMessages, setUserInfoMessages] = useState<Array<UserInformationMessageExtended>>([]);
    const [selectedUserInfoMessage, setSelectedUserInfoMessage] = useState<UserInformationMessageLightModel>(undefined);

    const cleanMessage = (message: string): string => {
        return message.replace(/[#*_]/g, ''); // Supprime les caract�res * et _
    };

    const dateToString = (date: Date): string => {
        return Date.getDateFromIsoString(date).toString();
    }

    useEffect(() => {
        getUserInformationMessages(true);
    }, []);

    const getUserInformationMessages = (setLoadingBeforeCall: boolean): void => {
        if (setLoadingBeforeCall) {
            setLoading(true);
        }
        UserInformationMessagesReferentialApiClient.GetUserInformationMessages()
            .then(response => {
                let data: Array<UserInformationMessageLightModel> = response.data;
                setUserInfoMessages(data.map(d => { return { ...d, messageCleaned: cleanMessage(d.message), selected: false, startDateStr: dateToString(d.startDate), endDateStr: dateToString(d.endDate) }; }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const addUserInfoMessage = (): void => {
        const today = new Date();
        const twoDaysLater = new Date();
        twoDaysLater.setDate(today.getDate() + 2);
        setSelectedUserInfoMessage({ startDate: today, endDate: twoDaysLater, message: '**Exemple de message**', messageColor: 'GREEN' } as UserInformationMessageLightModel);
    };

    const saveUserInfoMessage = (userInfoMessage: UserInformationMessageLightModel): Array<ErrorModel> => {

        let checkBeforeSave: (userInfoMessage: UserInformationMessageLightModel) => Array<ErrorModel> = (userInfoMessage: UserInformationMessageLightModel) => {
            let errors: Array<ErrorModel> = [];

            if (!userInfoMessage.startDate || !userInfoMessage.endDate) {
                errors.push({ code: 'ERR_DATES', label: 'La date de fin est requise.' });
            }
            if (!userInfoMessage.message) {
                errors.push({ code: 'ERR_MESSAGE', label: 'Le message est requis.' });
            }
            if (!userInfoMessage.roles) {
                errors.push({ code: 'ERR_ROLES', label: 'Les r�les sont requis.' });
            }

            return errors;
        };

        let functionToCall: (userInfoMessage: UserInformationMessageLightModel) => Promise<AxiosResponse<WebAppActionResult>> = undefined;
        if (userInfoMessage.id) {
            functionToCall = UserInformationMessagesReferentialApiClient.UpdateUserInformationMessage;
        } else {
            functionToCall = UserInformationMessagesReferentialApiClient.AddUserInformationMessage;
        }

        const errors = checkBeforeSave(userInfoMessage);
        if (errors.length > 0) {
            return errors;
        }

        setLoading(true);
        functionToCall(userInfoMessage)
            .then(_ => {
                setSelectedUserInfoMessage(undefined);
                getUserInformationMessages(false);
            })
            .finally(() => {
                setLoading(false);
            });

        return [];
    };

    const deleteUserInfoMessages = (): void => {
        setLoading(true);
        UserInformationMessagesReferentialApiClient.RemoveUserInformationMessages(userInfoMessages.filter(m => m.selected).map(m => m.id ?? 0))
            .then(_ => {
                getUserInformationMessages(false);
            })
            .finally(() => {
                setLoading(false);
            }
            );
    };

    const messageListComponent: JSX.Element = useMemo(() =>
        <UserInformationMessageListComponent
            items={userInfoMessages}
            onItemCreate={() => addUserInfoMessage()}
            onItemUpdate={i => setSelectedUserInfoMessage(i)}
            onItemDelete={() => deleteUserInfoMessages()}
            onItemSelect={i => setUserInfoMessages(userInfoMessages.map(m => { return m.id === i.id ? { ...m, selected: !m.selected } : m; }))}
            onItemSelectAll={checked => setUserInfoMessages(userInfoMessages.map(m => { return { ...m, selected: checked }; })) }
        />, [userInfoMessages]);

    return (
        <Box className="userinfomessage referencial">
            <div className="view-title">
                Messages d'information aux utilisateurs
            </div>
            <LocalizationProvider language="fr-FR">
                <IntlProvider locale="fr" >
                    <Box display="flex" flexDirection="column" flex="wrap">
                        {(loading ?
                            <ScaleLoaderComponent loading={loading} />
                            :
                            (<Box display='flex' flexDirection="row" width='100%'>
                                {messageListComponent}
                                {selectedUserInfoMessage && <AddUserInformationMessageComponent
                                    item={selectedUserInfoMessage}
                                    onSave={saveUserInfoMessage}
                                    onCancel={() => setSelectedUserInfoMessage(undefined)} />}
                            </Box>
                            )
                        )}
                    </Box>
                </IntlProvider>
            </LocalizationProvider>
        </Box>
    );
}
