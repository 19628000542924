import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton } from '@mui/material';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridColumnReorderEvent, GridColumnResizeEvent, GridHeaderSelectionChangeEvent, GridRowClickEvent } from '@progress/kendo-react-grid';
import React, { useMemo, useState } from 'react';
import { AppModule, LocalStorage } from '../../../../utils/Storage';
import { SizingUtilities } from '../../SizingUtilities';
import { UserInformationMessageExtended, UserInformationMessageLightModel } from '../models/UserInformationMessageLightModel';

interface UserInformationMessageListProps {
    items: Array<UserInformationMessageExtended>,
    onItemCreate: () => void,
    onItemUpdate: (item: UserInformationMessageLightModel) => void,
    onItemDelete: (item: UserInformationMessageLightModel[]) => void,
    onItemSelect: (item: UserInformationMessageLightModel) => void,
    onItemSelectAll: (checked: boolean) => void
}

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

const UserInfoMessagesGridName = 'userInformationMessages';
const ModuleKey = AppModule.UserInformationMessagesReferential;

export const UserInformationMessageListComponent = (props: UserInformationMessageListProps): JSX.Element => {
    const initialSort: SortDescriptor[] = [{ field: 'id', dir: 'desc' }];
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const { items } = props;
    
    const disabledBtnTrash = useMemo(() => items.filter(i => i.selected).length === 0, [items]);

    const forceUpdate = useForceUpdate();

    const getColumnWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, UserInfoMessagesGridName, fieldName, columnWidth);
    }

    const getColumnIndex = (fieldName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, UserInfoMessagesGridName, fieldName, defaultIndex);
    }

    const onResizeHandler = (event: GridColumnResizeEvent): void => {
        const currentColumn = event.columns.find(c => c.id == event.targetColumnId);

        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            UserInfoMessagesGridName,
            currentColumn.field,
            currentColumn.width);
    }

    const onReorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, UserInfoMessagesGridName, event.columns);
        forceUpdate();
    }

    const onItemSelected = (event: GridRowClickEvent): void => {
        props.onItemSelect(event.dataItem);
    };

    const onAllItemsSelected = (event: GridHeaderSelectionChangeEvent): void => {
        const checked = event.nativeEvent.target.checked;
        props.onItemSelectAll(checked);
    };

    const handleRowClick = (event: GridRowClickEvent): void => {
        props.onItemSelect(event.dataItem);
    }

    const gridOffsetFromWindowTop: number = SizingUtilities.gridOffsetFromWindowTop();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
    const rowHeight: number = SizingUtilities.rowHeight;
    const gridStyle: React.CSSProperties = { height: gridHeight };
    const resize = (): void => {
        gridHeight = window.innerHeight - gridOffsetFromWindowTop;
        forceUpdate();
    }
    window.onresize = resize;

    const gridData: UserInformationMessageExtended[] = useMemo(() => {
        return orderBy(props.items, sort);
    }, [props.items, sort]);

    return (
        <Box display="flex" flexDirection="column" flex="wrap" className="user-info-message-list">
            <Box display="flex" flexDirection="row" className="user-info-message-header">
                <IconButton size="small" className="btn-icon" aria-label="Add"
                    disabled={disabledBtnTrash} onClick={() => props.onItemDelete(items.filter(i => i.selected))}>
                    <FontAwesomeIcon className={disabledBtnTrash ? 'fa-trash-disabled' : 'fa-trash'} icon={faTrash} />
                </IconButton>
                <span>
                    <Button onClick={() => props.onItemCreate()} variant="contained" color="primary" style={{ marginRight: '10px' }}>Ajouter</Button>
                    <Button onClick={() => props.onItemUpdate(items.find(i => !!i.selected))} variant="contained" color="primary" disabled={items.filter(i=>!!i.selected).length !== 1}>Modifier</Button>
                </span>
            </Box>
            <Box display="flex" flexDirection="row" flex="wrap" className="colas_user-info-message-grid">
                <Grid
                    className="user-info-message-grid"
                    data={gridData}
                    selectedField="selected"
                    sortable
                    reorderable
                    resizable
                    sort={sort}
                    onSelectionChange={e => onItemSelected(e)}
                    onHeaderSelectionChange={e => onAllItemsSelected(e)}
                    onRowClick={e => handleRowClick(e)}
                    onColumnResize={onResizeHandler}
                    onColumnReorder={(e) => onReorderHandler(e)}
                    onSortChange={s => setSort(s.sort)}
                    rowHeight={rowHeight}
                    scrollable="virtual"
                    style={gridStyle}
                >
                    <GridColumn field="selected" orderIndex={getColumnIndex("selected", 0)} width={getColumnWidth("selected", 25)}
                        headerSelectionValue={items.length > 0 && items.every(i => !!i.selected)} headerClassName="cell-center" className="cell-center" />
                    <GridColumn field="id" orderIndex={getColumnIndex("id", 1)} width={getColumnWidth("id", 80)} title="ID" />
                    <GridColumn field="startDateStr" orderIndex={getColumnIndex("startDateStr", 2)} width={getColumnWidth("startDateStr", 120)} title="Date de début" />
                    <GridColumn field="endDateStr" orderIndex={getColumnIndex("endDateStr", 3)} width={getColumnWidth("endDateStr", 120)} title="Date de fin" />
                    <GridColumn field="messageCleaned" orderIndex={getColumnIndex("messageCleaned", 4)} width={getColumnWidth("messageCleaned", 310)} title="Message" />
                    <GridColumn field="roles" orderIndex={getColumnIndex("roles", 5)} width={getColumnWidth("roles", 310)} title="Rôles" />
                </Grid>
            </Box>
        </Box>
    );
}
